<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12">
        <student-tab-overview :groupsData="groupsData" :userData="userDataStorage"></student-tab-overview>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'

import { onUnmounted, ref, watch } from 'vue'
import StudentTabOverview from '../user/user-view/student-tab-overview/StudentTabOverview.vue'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiLockOutline } from '@mdi/js'

export default {
  components: {
    StudentTabOverview,
  },
  setup() {

    const groupsData = ref([])
    const userDataStorage = store.getters['user/getUser']

    const loadUser = () => {
      store
        .dispatch('user/fetchCourses')
        .then(response => {
          groupsData.value = response.data.groups
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }

    loadUser()

    watch(
      () => router.currentRoute.params,
      (toParams, previousParams) => {
        loadUser()
      },
    )

    return {
      userDataStorage,
      groupsData,
      router,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

tbody {
  tr:hover {
    cursor: pointer !important;
  }
}
</style>
